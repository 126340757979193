import * as React from "react"
import "./Related.scss"
import {Link} from "gatsby";
import Image from "../image/Image";

const Related = ({related, category, siteBaseUrl}) => {
    return (
        <div className="post_entry-related_posts_container">
            <div className="post_entry-related_posts_title">
                <h3>También te puede interesar</h3>
            </div>
            <div className="post_entry-related_posts">
                {
                    related.map(({node}, idx) => {
                        const title = node.frontmatter.title || ''
                        const uri = `${ siteBaseUrl }/${ category }/${ node.frontmatter.slug }/`

                        return (
                            <div className="post_entry-item_container" key={`${title}-${idx}`}>
                                <div className="post_entry-item_image-container">
                                    <Link to={ uri } title={ title }>
                                        <Image
                                            width={195}
                                            height={195}
                                            alt={ title }
                                            title={ title }
                                            className="post_entry-related_item_image"
                                            fileName={ node.frontmatter.thumb || 'posts/default-post-entry.png' }
                                        />
                                    </Link>
                                </div>
                                <Link to={ uri } title={ title } className="post_entry-item_title">
                                    <h4>{ title }</h4>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Related
