import React, { useState } from "react"
import PropTypes from "prop-types"
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TumblrShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TumblrIcon,
    TwitterIcon
} from "react-share"
import { Icon } from '@iconify/react'
import "./CtfFlag.scss"

const CtfFlag = ({ userFlag, rootFlag, postTitle, siteTitle }) => {
    const [hideFlag, setHideFlag] = useState(true)
    const shareUrl = typeof window !== 'undefined' ? window.location.href : '';
    const title = `🔥🔥🔥 Acabo de conseguir las flags de ${postTitle} en ${siteTitle} @okboomersh 👉 `

    return (
        <div className="ctf_container">
            <div className="ctf_name_container">
                <div>
                    <Icon icon="ant-design:warning-filled" />
                </div>
                <div>
                    Las flags que resuelven este reto se encuentran protegidas. Comparte el artículo en redes sociales para desbloquearlas.
                </div>
            </div>
            <div className="ctf_networks">
                <div className="Demo__some-network">
                    <FacebookShareButton
                        url={ shareUrl }
                        quote={ title }
                        className="Demo__some-network__share-button"
                        onShareWindowClose={ () => setHideFlag(false) }
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>
                <div className="Demo__some-network">
                    <LinkedinShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={ () => setHideFlag(false) }
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
                <div className="Demo__some-network">
                    <RedditShareButton
                        url={shareUrl}
                        title={title}
                        windowWidth={660}
                        windowHeight={460}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={ () => setHideFlag(false) }
                    >
                        <RedditIcon size={32} round />
                    </RedditShareButton>
                </div>
                <div className="Demo__some-network">
                    <TumblrShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={ () => setHideFlag(false) }
                    >
                        <TumblrIcon size={32} round />
                    </TumblrShareButton>
                </div>
                <div className="Demo__some-network">
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button"onShareWindowClose={ () => setHideFlag(false) }

                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
            </div>
            <div className="ctf_flags_container">
                {
                    userFlag && (
                        <div className="ctf_flag_container">
                            <div className="ctf_flag_title">
                                <span>$</span>
                                <span>User Flag</span>
                            </div>
                            <div className="ctf_flag_body">
                                {
                                    hideFlag ? `${userFlag.substr(0,8)}...` : userFlag
                                }
                            </div>
                        </div>
                    )
                }
                {
                    rootFlag && (
                        <div className="ctf_flag_container">
                            <div className="ctf_flag_title">
                                <span>#</span>
                                <span>Root Flag</span>
                            </div>
                            <div className="ctf_flag_body">
                            {
                                hideFlag ? `${rootFlag.substr(0,8)}...` : rootFlag
                            }
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

CtfFlag.propTypes = {
    userFlag: PropTypes.string.isRequired,
    rootFlag: PropTypes.string.isRequired,
    postTitle: PropTypes.string.isRequired,
    siteTitle: PropTypes.string.isRequired
}

export default CtfFlag
