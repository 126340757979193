import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo, ProductJsonLd, ArticleJsonLd } from 'gatsby-plugin-next-seo';
import MainLayout from "../components/mainLayout/MainLayout"
import BreadCrumb from "../components/breadCrumb/BreadCrumb"
import Tags from "../components/tags/Tags"
import config from "../config/config"
import Related from "../components/related/Related";
import CtfFlag from "../components/ctfFlag/CtfFlag";
import "./Article.scss"

const Article = (meta) => {
    const slug = meta.path || meta.uri || ''
    const siteTitle = meta.data.site.siteMetadata.title || ''
    const siteAuthor = meta.data.site.siteMetadata.author || ''
    const siteBaseUrl = meta.data.site.siteMetadata.siteUrl || ''
    const { markdownRemark } = meta.data
    const { frontmatter: post } = markdownRemark
    const category = post.categories[0]
    const date = (new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
    const transformedLocale = config.meta.locale.toLowerCase().replace("_","-")
    const image = `${ siteBaseUrl }${ post.featuredImage.publicURL }`
    const { edges: relatedPosts } = meta.data.allMarkdownRemark
    const seotitle = post.seo_title || `Resolución del reto ${post.title}` || ''
    let dificultadClassName = '';
    switch(post.dificultad) {
        case 'Fácil':
            dificultadClassName = 'post-entry_dificultad-easy';
            break;
        case 'Medio':
            dificultadClassName = 'post-entry_dificultad-medium';
            break;
        case 'Difícil':
            dificultadClassName = 'post-entry_dificultad-hard';
            break;
        default:
            dificultadClassName = '';
            break;
    }

    return (
        <MainLayout>
            <GatsbySeo
                title={ seotitle }
                titleTemplate={ `%s | ${siteTitle}` }
                description={ post.description }
                language={ config.meta.lang }
                metaTags={[
                    { property: 'author', name: 'author', content: siteAuthor },
                    { property: 'copyright', name: 'copyright', content: siteAuthor },
                    { property: 'publisher', name: 'publisher', content: siteAuthor },
                    { property: 'keywords', name: 'keywords', content: post.tags },
                    { property: 'content-language', name: 'content-language', content: transformedLocale }
                ]}
                openGraph={{
                    title: seotitle,
                    description: post.description,
                    url: post.canonical,
                    type: 'article',
                    article: {
                        publishedTime: post.date,
                        section: post.categories[0],
                        authors: [ siteAuthor ],
                        tags: post.tags,
                    },
                    images: [
                        {
                            url: image,
                            width: 1200,
                            height: 630,
                            alt: post.title,
                        },
                    ],
                }}
                twitter={{
                    site: siteAuthor,
                    handle: siteAuthor,
                    cardType: 'summary_large_image'
                }}
            />
            <BreadCrumb category={category} post={post}/>
            <h1 className="post-entry_title coloured">{post.title}</h1>
            {
                post?.tags && <Tags tags={post.tags}/>
            }
            {
                post?.dificultad && <span className={`post-entry_dificultad ${dificultadClassName}`}>{ post.dificultad }</span>
            }
            {
                post?.os && <span className="post-entry_os">{ post.os }</span>
            }
            <div>
                <CtfFlag
                    userFlag={ post.user_flag }
                    rootFlag={ post.root_flag }
                    postTitle={ post.title }
                    siteTitle={ siteTitle }
                />
            </div>
            <div dangerouslySetInnerHTML={{__html: markdownRemark.html}} className="post-entry_content"/>
            <Related related={ relatedPosts } category={category} siteBaseUrl={ siteBaseUrl }/>
            <ProductJsonLd
                sku={markdownRemark.id}
                mpn={markdownRemark.id}
                productName={seotitle}
                images={[ image ]}
                description={post.description}
                brand={post.categories[0]}
                reviews={[
                    {
                        author: siteAuthor,
                        datePublished: date,
                        reviewBody: post.description,
                        name: seotitle,
                        reviewRating: {
                            bestRating: '5',
                            ratingValue: '5',
                            worstRating: '4',
                        },
                    },
                ]}
                offers={{}}
                aggregateRating={{
                    ratingValue: '4.4',
                    reviewCount: '89',
                }}
            />
            <ArticleJsonLd
                url={ post.canonical }
                headline={ seotitle }
                images={[ image ]}
                datePublished={ post.date }
                dateModified={ post.date }
                authorName={ siteAuthor }
                authorUrl={ siteAuthor }
                publisherName={ siteAuthor }
                publisherLogo='https://www.example.com/photos/logo.jpg'
                description={ post.description }
                overrides={{
                    '@type': 'BlogPosting'
                }}
            />
        </MainLayout>
    )
}

export const query = graphql`
    query ArticleQuery($id: String!, $related: [String!]) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date
                slug
                tags
                title
                seo_title
                thumb
                os
                dificultad
                related
                categories
                description
                user_flag
                root_flag
                canonical
                featuredImage {
                    publicURL
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
                author
            }
        }
        allMarkdownRemark(filter: { frontmatter: { slug: { in: $related } } }) {
            edges {
                node {
                    frontmatter {
                        slug
                        thumb
                        title
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid {
                                    sizes
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default Article
